#dataTable_wrapper{

    margin-bottom: 20px;

    .table {
        max-width: none;
        table-layout: fixed;
        word-wrap: break-word;
        //  border: none;

        th{
            font-size: 12px;
            background-color: $azzurro;
            color: #ffffff;
            text-transform: uppercase;
        }

        th.action_column{
            width: 160px;
        }

        thead{
            th{
                padding-bottom: 8px !important;
            }

            th:after{
                bottom: auto;
                top: 9px;
            }
        }

        tfoot{
            th{
                input{
                    color: black !important;
                }
            }
        }
    }

    table.dataTable thead>tr>th.sorting_asc, table.dataTable thead>tr>th.sorting_desc, table.dataTable thead>tr>th.sorting, table.dataTable thead>tr>td.sorting_asc, table.dataTable thead>tr>td.sorting_desc, table.dataTable thead>tr>td.sorting{
        padding-bottom: 30px;
        padding-right: 8px;
        text-align: center;
        &:after{
            bottom: 0px;
        }
    }



    .dataTables_info{
        float: left;
    }

    .dataTables_length{
        float: left;
    }
}

@media only screen and (max-device-width: 736px){ 
    #dataTable_wrapper{
        .table {
            th.action_column{
                width: 40px;
            }
        }
    }
}