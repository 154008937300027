h1{
	color: $azzurro;
	text-transform: uppercase;
    font-size: 28px;
}

.logo_print {
	max-width: 700px;
}

.title_page{
	.btn-crea{
		float: right;
	}

}

.box-ping{
	margin-bottom: 30px;
	.title{
		background-color: $azzurro;
		color: #ffffff;
		padding: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #fff;
		h4{
			display: inline-block;
			margin-right: 20px;
		}
		.btn{
			float: right;
			margin-right: 20px;
		}
	}
	.corpo{
		background-color: $grigio;
	}
}

.btn-primary{
	background-image: linear-gradient(to bottom, $azzurro 0%, #337ab7 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF337AB7', endColorstr='#FF265A88', GradientType=0);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
	background-repeat: repeat-x;
	border-color: $azzurro;
}
.btn-default{
	border-color: $azzurro;
	color: $azzurro;
	background-image: linear-gradient(to bottom, #fff 0%, #FFFFFF 100%);
}
.btn-default:hover, .btn-default:focus {
	background-color: #ffffff;
	background-position: 0 -33px;
}


.bg-danger, .bg-success{
    padding: 20px;
    text-align: center;
}

.error_message{
    border-color: red;
    color: red;
}

.warning_message{
    border-color: #FFCC33;
    color: #FFCC33;
}

.prontoIcon{
    font-size: 21px;
}

/**************/
/* stile form */
/**************/
form{

	label{
		text-transform: capitalize;
	}
}

.item_toolbar{
    height: 34px;
}

.panel-info{
    margin-top: 30px;
}

.show-record{
    margin-top: 20px;

    .control-label{
        padding-top: 0px;
    }
}

tr.disabled{
	opacity: 0.3;
	background-color: #8a8a8a;
	input,
	textarea {
		pointer-events:none;
	}
	button{
		display: none;
	}
	a:not(.not_disable_link) {
		 pointer-events: none;
		 cursor: default;
		 text-decoration: none;
		 color: black;
	}

}