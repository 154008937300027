@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/theme";


@import "./_bootstrap-datetimepicker";

$azzurro: #289bd4;
$blu: #3366FF;
$blu_chiaro: #33CCFF;
$grigio: #F5F5F6;
$grigio_scuro: #D6DEE3;

@import './_navbar';
@import './_errors';
@import './_login';
@import './tables';
@import './_custom-ping';
@import './_lavorazioni';
@import './_messaggi';