
$grigio_box: #999;

.lavorazione{

    clear:both;
    margin-bottom: 10px;
    display: flex;
    width: 100%;

    >*{
        padding: 20px;
        border-bottom: 1px solid $grigio_box;
        flex-grow: 1;
    }

    .input-group-addon{
            display: inline-block;
            position: relative;
    }
    a{
        float: right;
        width: 5%;
    }

    .divider{

        padding: 0px;
        clear:both;
        border: 0px;
        //border-top: 1px solid $grigio_box;
        width: 100%;
        margin-top:10px;
        margin-bottom: 10px;
    }

    .checkbox_container{
        float: right;
        width: 40px;
        padding: 0px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid $grigio_box;
        border-left: 0px;
//        border-radius: 10px;

        input{
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .checkbox_container_alert{
        float: right;
        width: 80px;
        padding: 3px;
        padding-left: 20px;
        border-left: 0px;

        span{
         padding: 3px;
         font-size: 16px;
            &:hover{
                background-color: rgb(240, 173, 78);
            }
        }
    }


}

.lavorazione.annullata{
    	opacity: 0.3;
    	background-color: #8a8a8a;

    	input,textarea {
    	    pointer-events: none;
    	}

    	button {
    	    display: none;
    	}

    	a:not(.not_disable_link) {
    	    pointer-events: none;
    	    cursor: default;
    	    text-decoration: none;
    	    color: black;
    	}
}
 
// Lavorazioni per fornitore
.lavorazione-fornitore{

    .scadenza{
        padding: 0px;
        input{
            height: 32px;
            outline: none;
            border: 0px;
        }
    }
    
    .note{
        height: 22px;
    }
}

.lavorazione-disabled{
    opacity: 0.5;
}

#accordion{

    margin-top: 20px;

    .btn-crea{
        margin-bottom: 10px;
    }

}