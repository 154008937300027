#box-messaggi{

    .messaggio {
        padding: 15px;

        .data {
            float: left;
            color: black;
        }

        .messaggio_title {
            float: left;
            font-weight: bold;
            margin-left: 10px;
        }

        .text {
            clear: both;
        }
    }
}

