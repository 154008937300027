
// Stile per la form di login

.login_form{

    max-width: 300px;
    margin-left: auto;
    margin-right: auto;

    input{
        margin-top: 10px;
    }

    .btn-primary{
        margin-top: 20px;
    }

}