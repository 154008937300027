.comarp_topbar{

	background: $azzurro none !important;
	//height: 100px;

    .username{
        float: right;
        color: #FFFFFF;
        margin-top: -35px;
        margin-right: 10px;
    }

	.comarp_logout{
		float: right;
		margin: 10px;
		padding: 10px;
		background-color: #FFFFFF;
		color: $azzurro !important;
		min-width: 100px;
		text-align: center;
	}

	.logo{
		margin-top: 15px;
		max-width: 150px;
	}

	a{
		color: #FFFFFF !important;
	}

	.active{
		a{
			background: $blu none !important;
		}
	}

	.navbar-nav{
		margin-top: 49px;
		margin-left: 20px;
	}

	.dropdown-menu > li > a{
		color: $azzurro !important;
	}

	.navbar-nav > .open > a{
		background: $blu_chiaro none !important;
	}

}